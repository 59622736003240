<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Collaboration List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['collaborations/create'])"
                       :to="'/collaborations/create/'"
                       class="btn btn-info btn-wd" wide>
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>

        <!--    filters    -->
        <div class="row col-12 mb-2">
          <div class="col-md-4 col-sm-4">
            <fg-select
              size="large"
              filterable
              clearable
              placeholder="Select Category"
              :input-classes="'select-default'"
              :list="filterBuilders.categories"
              :listItemLabel="'name'"
              :listItemValue="'id'"
              v-model="filters.category_id">
            </fg-select>
          </div>

          <div class="col-md-2 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">Filter Result</l-button>
          </div>
        </div>
        <!--  end  filters    -->

        <div>
          <general-data-table
            ref="table"
            :sortable="true"
            :row-key="'id'"
            :key="componentKey"
            @reorder="reorder($event)"
            :api-url="'collaborations/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['collaborations/change-active-status'])"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <template>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['collaborations/update']) ||
                                     $store.getters['auth/checkAccessOwner']( scope.row.creator_id)"
                               v-tooltip.top-center="'Edit'"
                               class="btn-warning btn-simple btn-link"
                               :to="'/collaborations/edit/'+scope.row.id">
                    <i class="fa fa-edit"></i>
                  </router-link>

                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['collaborations/delete']) ||
                           $store.getters['auth/checkAccessOwner'](  scope.row.creator_id)"
                     v-tooltip.top-center="'Delete'"
                     class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id)">
                    <i class="fa fa-times"></i></a>
                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this Collaboration?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "../../components/Inputs/formGroupSelect";
import LSwitch from "../../components/Switch";


export default {
  components: {
    LSwitch,
    FgSelect,
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
        {label: 'Category', value: 'category.name', minWidth: '200', align: 'center'},
         {label: 'Location', value: 'location', minWidth: '200', align: 'center'},
         {label: 'Date', value: 'date', minWidth: '200', align: 'center'},
      ],

      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
      filterBuilders: {
        categories: []
      },

      filters: {
        category_id: null
      },
    }
  },
  created(){
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  async mounted() {
    let data = {};
    let response = await this.axios.post("collaborations/builder", data);
    this.filterBuilders.categories = response.data.categories;
  },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
      }
      this.axios.post("collaborations/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Collaboration updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },
    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    async confirmDeleteModal() {
      try {
        let data = {
            'id': this.toDeleteId,
        }
        await this.axios.delete("collaborations/delete", {headers: {}, data});
        this.$refs.table.getData("updateData");
        this.$notify({
          message: "Collaboration deleted successfully",
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.category_id) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },
    async reorder(data) {
      console.log('consol');
      try {
        let body = {};
        let orderData = [];
        data.forEach((row, i) => {
          orderData.push({"id": row.id, "sort_order": i + 1})
        });
        body = {
          data: orderData,
        }

        await this.axios.post("collaborations/re-order", body);

        this.$notify({
          message: "order updated successfully",
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },
  },
}
</script>

